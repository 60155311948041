import urlUtil from "./url.util";

const util = {
  getAdminAbsoluteUrl: (product, path) => {
    var groupName = product.productGroup.name;
    var productName = product.name;
    var url = `/admin/produktGrupper/${urlUtil.safeEncodeComponent(
      groupName
    )}/produkter/${urlUtil.safeEncodeComponent(productName)}`;

    if (path) {
      url += "/" + path;
    }

    return url;
  },
  getAbsoluteUrl: (product, path) => {
    var groupName = product.productGroup.name;
    var productName = product.name;
    var url = `/produktGrupper/${urlUtil.safeEncodeComponent(
      groupName
    )}/${urlUtil.safeEncodeComponent(productName)}`;

    if (path) {
      url += "/" + path;
    }

    return url;
  },
  getUrlSegments: (product) => {
    var groupName = product.productGroup.name;
    var productName = product.name;
    var url = `/${urlUtil.safeEncodeComponent(
      groupName
    )}/${urlUtil.safeEncodeComponent(productName)}`;

    return url;
  },
};

export default util;
